import "./styles/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Protected, Public, Admin } from "./middleware/route";
import { Helmet } from "react-helmet";
import Tracking from "./components/Tracking";
import Schema from "./components/Schema";
import Loading from "./components/Loading";

// Lazy load pages
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Profile = lazy(() => import("./pages/Profile"));
const Home = lazy(() => import("./pages/Home"));
const Doctors = lazy(() => import("./pages/Doctors"));
const Appointments = lazy(() => import("./pages/Appointments"));
const Error = lazy(() => import("./pages/Error"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ApplyDoctor = lazy(() => import("./pages/ApplyDoctor"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const BookAppointment = lazy(() => import("./pages/BookAppointment"));
const AppointmentSteps = lazy(() => import("./components/AppointmentSteps"));
const Teams = lazy(() => import("./components/Team/Teams"));
const ServiceDetails = lazy(() => import("./components/Services/ServiceDetails"));
const Gallery = lazy(() => import("./components/Gallery/Gallery"));
const GalleryDetail = lazy(() => import("./components/Gallery/GalleryDetail"));
const CareerApply = lazy(() => import("./components/Career/CareerApply"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const Holiday = lazy(() => import("./pages/Holiday"));
const Service = lazy(() => import("./components/Services/Services"));
const MainakProfile = lazy(() => import("./components/Team/Mainak"));
const DikshaProfile = lazy(() => import("./components/Team/Diksha"));
const DipteshProfile = lazy(() => import("./components/Team/Diptesh"));
const SampritiProfile = lazy(() => import("./components/Team/Sampiriti"));
const PrivacyPolicy = lazy(() => import("./components/Policies/Privacy"));
const CookiePolicy = lazy(() => import("./components/Policies/Cookie"));
const TermsAndConditions = lazy(() => import("./components/Policies/Terms"));

function App() {
  return (
    <Router>
      <Tracking />
      <Helmet>
        <title>Physiotherapy & Occupational Therapy | MS Thera-Cure Clinic Kolkata</title>
        <meta name="description" content="Get expert physiotherapy and occupational therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Personalized care for your recovery. Book now!" />
      </Helmet>
      <Schema />
      <Toaster />
      
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Public><Register /></Public>} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Home />} />
          <Route path="/conditions-we-treat" element={<Home />} />
          <Route path="/whyus" element={<Home />} />
          <Route path="/about" element={<Home />} />
          <Route path="/holiday" element={<Holiday />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/appointments" element={<Protected><Appointments /></Protected>} />
          <Route path="/book-appointments" element={<Protected><BookAppointment /></Protected>} />
          <Route path="/appointment" element={<AppointmentSteps />} />
          <Route path="/our-team" element={<Teams />} />
          <Route path="/service" element={<Service />} />
          <Route path="/services/:servicesType" element={<ServiceDetails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:name" element={<GalleryDetail />} />
          <Route path="/careers" element={<CareerApply />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/our-team/mainak" element={<MainakProfile />} />
          <Route path="/our-team/diksha" element={<DikshaProfile />} />
          <Route path="/our-team/diptesh" element={<DipteshProfile />} />
          <Route path="/our-team/sampriti" element={<SampritiProfile />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndConditions />} />
          <Route path="/notifications" element={<Protected><Notifications /></Protected>} />
          <Route path="/applyfordoctor" element={<Protected><ApplyDoctor /></Protected>} />
          <Route path="/profile" element={<Protected><Profile /></Protected>} />
          <Route path="/dashboard/users" element={<Admin><Dashboard type="users" /></Admin>} />
          <Route path="/dashboard/message" element={<Admin><Dashboard type="message" /></Admin>} />
          <Route path="/dashboard/doctors" element={<Admin><Dashboard type="doctors" /></Admin>} />
          <Route path="/dashboard/pop-up" element={<Admin><Dashboard type="pop-up" /></Admin>} />
          <Route path="/dashboard/appointments" element={<Protected><Dashboard type="appointments" /></Protected>} />
          <Route path="/dashboard/careers" element={<Protected><Dashboard type="careers" /></Protected>} />
          <Route path="/dashboard/careers-data" element={<Protected><Dashboard type="careers-data" /></Protected>} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
